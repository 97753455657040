<template>
  <div>
    <el-dialog
      @close="close"
      title="物流信息"
      :visible.sync="logisticsVisible"
      :append-to-body="true"
    >
      <el-form ref="form" label-width="80px" v-if="logisticsData">
        <el-form-item label="物流公司">
          <span>{{ logisticsData.com }}</span>
          <el-button type="text" size="small" @click="logisticsRevise()"
            >修改
          </el-button>
        </el-form-item>
        <el-form-item label="物流单号">
          <span>{{ logisticsData.number }}</span>
          <el-button type="text" size="small" @click="logisticsRevise()"
            >修改
          </el-button>
        </el-form-item>
        <el-form-item label="物流状态">
          <span>运输中</span>
        </el-form-item>
        <el-form-item label="物流详情">
          <div>
            <el-timeline :reverse="true">
              <el-timeline-item
                v-for="(activity, index) in logisticsData.traces"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.desc }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      logisticsVisible: false,
      reverse: true,
      logisticsData: null,
    };
  },
  methods: {
    close() {
      this.logisticsVisible = false;
      this.$emit('close', false);
    },
    // 修改
    logisticsRevise() {
      this.$emit('logisticsRevise');
    },
    // 查询物流信息
    queryWLInfo(num) {
      let resData = {
        number: num,
      };
      this.$api.order.queryWLInfo(resData).then((res) => {
        const data = res.data;
        console.log(data);
        if (data.ret === 200) {
          this.logisticsData = data.data;
          this.logisticsVisible = true;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
